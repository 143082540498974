type Properties = {
  className?: string;
  size: number;
};

export const IdrissArrowRight = ({ size, className }: Properties) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 9.25008C2.91977 9.25008 2.58398 9.58587 2.58398 10.0001C2.58398 10.4143 2.91977 10.7501 3.33398 10.7501V9.25008ZM16.6673 10.0001V10.7501C16.9707 10.7501 17.2441 10.5673 17.3602 10.2871C17.4763 10.0068 17.4121 9.68425 17.1976 9.46975L16.6673 10.0001ZM11.3643 3.63642C11.0714 3.34352 10.5965 3.34352 10.3037 3.63642C10.0108 3.92931 10.0108 4.40418 10.3037 4.69708L11.3643 3.63642ZM10.3037 15.3031C10.0108 15.596 10.0108 16.0709 10.3037 16.3637C10.5965 16.6566 11.0714 16.6566 11.3643 16.3637L10.3037 15.3031ZM14.6976 13.0304C14.9905 12.7375 14.9905 12.2626 14.6976 11.9698C14.4048 11.6769 13.9299 11.6769 13.637 11.9698L14.6976 13.0304ZM3.33398 10.7501H16.6673V9.25008H3.33398V10.7501ZM17.1976 9.46975L11.3643 3.63642L10.3037 4.69708L16.137 10.5304L17.1976 9.46975ZM11.3643 16.3637L14.6976 13.0304L13.637 11.9698L10.3037 15.3031L11.3643 16.3637Z"
        fill="currentColor"
      />
    </svg>
  );
};
