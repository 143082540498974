type Properties = {
  className?: string;
  size: number;
};

export const Farcaster = ({ size, className }: Properties) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4429_1949" fill="white">
        <path d="M5.44727 2.00195H19.5103V21.9999H17.446V12.8396H17.4258C17.1976 10.3078 15.0699 8.32387 12.4788 8.32387C9.8877 8.32387 7.75997 10.3078 7.5318 12.8396H7.51157V21.9999H5.44727V2.00195Z" />
      </mask>
      <path
        d="M5.44727 2.00195H19.5103V21.9999H17.446V12.8396H17.4258C17.1976 10.3078 15.0699 8.32387 12.4788 8.32387C9.8877 8.32387 7.75997 10.3078 7.5318 12.8396H7.51157V21.9999H5.44727V2.00195Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.0580586"
        mask="url(#path-1-inside-1_4429_1949)"
      />
      <path
        d="M1.70508 4.83691L2.54371 7.67534H3.2533V19.1581C2.89702 19.1581 2.60821 19.4469 2.60821 19.8031V20.5773H2.4792C2.12293 20.5773 1.83408 20.8661 1.83408 21.2224V21.9965H9.05916V21.2224C9.05916 20.8661 8.77035 20.5773 8.41407 20.5773H8.28506V19.8031C8.28506 19.4469 7.99622 19.1581 7.63995 19.1581H6.86585V4.83691H1.70508Z"
        fill="currentColor"
      />
      <path
        d="M17.5735 19.1581C17.2172 19.1581 16.9284 19.4469 16.9284 19.8031V20.5773H16.7994C16.4431 20.5773 16.1543 20.8661 16.1543 21.2224V21.9965H23.3794V21.2224C23.3794 20.8661 23.0905 20.5773 22.7343 20.5773H22.6052V19.8031C22.6052 19.4469 22.3164 19.1581 21.9602 19.1581V7.67534H22.6698L23.5084 4.83691H18.3476V19.1581H17.5735Z"
        fill="currentColor"
      />
    </svg>
  );
};
