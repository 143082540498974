type Properties = {
  className?: string;
};

export const AavegotchiLogo = ({ className }: Properties) => {
  return (
    <svg
      className={className}
      width="190"
      height="32"
      viewBox="0 0 190 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1547764655">
        <path
          id="Vector"
          d="M45.3646 24.1366L43.5044 10.8729C42.7897 5.77834 37.5855 1.22656 32.4753 1.22656C30.436 1.22656 28.2488 2.11607 26.5558 4.31398C25.6825 5.47107 25.0487 6.79269 24.6914 8.20067C24.3342 9.60867 24.261 11.0745 24.476 12.5114L26.7458 28.7034L35.1543 28.6904C34.9394 28.3862 34.7909 28.0397 34.7187 27.6736C34.5478 26.965 34.2914 25.2722 34.2914 25.2722C34.2907 25.2669 34.2904 25.2618 34.2896 25.2565L33.8342 21.9927C33.7242 21.2035 34.2318 20.6635 35.022 20.617C35.882 20.5665 36.7879 20.9104 37.1067 22.4415L37.4158 24.6568L37.417 24.6644L37.9807 28.7034C37.9807 28.7034 46.6412 28.7423 46.6389 28.7402C46.0054 28.1222 45.3646 24.1366 45.3646 24.1366ZM34.7796 15.7695C34.2665 15.7914 33.7644 15.6149 33.3765 15.2761C32.9886 14.9373 32.744 14.4619 32.6932 13.9474L32.4321 12.1119C32.3228 11.3444 32.3805 10.2966 32.7781 9.84613C32.933 9.66346 33.1279 9.51957 33.3475 9.42582C33.5672 9.33206 33.8053 9.29103 34.0435 9.30597C34.3743 9.32304 34.6898 9.45111 34.94 9.66982C35.1901 9.88852 35.3603 10.1853 35.4235 10.5127L35.9685 14.3908C36.0043 14.5638 35.9999 14.7428 35.9555 14.9138C35.9112 15.0848 35.8284 15.2432 35.7132 15.3766C35.5981 15.51 35.454 15.6149 35.292 15.6831C35.13 15.7512 34.9547 15.7808 34.7796 15.7695Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M181.959 28.6696C180.9 28.6177 180.059 28.6409 179.452 28.6409C178.824 28.6409 177.76 28.6136 177.76 28.6136C178.022 28.2035 178.195 27.3937 178.276 26.8001C178.334 26.3708 178.362 26.1777 178.469 25.4685C179.005 21.955 179.587 17.8439 180.085 14.3248C180.442 11.817 182.477 10.4259 184.4 10.7441C186.534 11.097 188.045 12.8019 187.723 15.2666C187.21 19.2015 186.678 21.7929 186.173 25.7288C185.914 27.2384 184.372 28.5788 183.097 28.6436C182.429 28.6776 182.711 28.6696 181.959 28.6696Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M186.826 1.29971C186.812 1.25009 186.702 1.23608 186.702 1.23608L185.963 1.12415V1.12299L185.937 1.12039L185.912 1.11659V1.11779L185.168 1.04114C185.168 1.04114 185.058 1.02716 185.033 1.07168C185.006 1.1162 182.201 3.11217 182.201 3.11217L181.797 6.3316L184.073 9.0406L185.787 9.25847L188.661 7.20427L189.065 3.98484C189.065 3.98484 186.84 1.34933 186.826 1.29971Z"
          fill="currentColor"
        />
        <path
          id="Vector_4"
          d="M54.6175 28.7148C52.4097 28.7399 52.0376 28.7898 51.5912 28.2654C49.6399 25.9732 46.4892 23.3493 46.8647 19.8062C47.3389 15.3345 48.075 10.8886 48.7819 6.44502C49.043 4.80373 50.1055 3.79597 51.8059 3.61186C54.3338 3.33813 56.0322 5.1681 55.7514 7.7863C55.3804 11.2438 54.8284 14.6144 54.3564 18.0604C54.2084 19.1406 53.9894 20.4504 55.4332 20.6969C57.1229 20.9852 57.3665 19.2184 57.4643 18.2575C57.9987 14.4773 58.7348 9.21731 59.3243 5.44564C59.7014 3.03282 61.3603 1.6186 63.5579 1.97166C65.8583 2.34124 66.7392 4.36157 66.3966 6.45583C65.8468 9.81703 65.1961 14.8302 64.776 18.21C64.1806 23.0014 61.5267 25.7477 57.5745 28.1006C56.4855 28.8274 56.1307 28.7535 54.6175 28.7148Z"
          fill="currentColor"
        />
        <path
          id="Vector_5"
          d="M73.4641 28.6198C71.2886 28.6198 68.8932 28.6198 66.6904 28.6198C66.2954 28.6198 65.2598 28.608 65.2598 28.608C65.4724 28.2526 65.7209 26.4643 65.7892 26.0384C66.6773 20.189 67.1164 15.4247 68.1276 9.59625C68.662 6.51548 71.6164 3.78619 74.9315 3.54622C76.893 3.40425 78.1293 3.44766 80.0986 3.44766C82.7467 3.44766 84.4445 5.2506 84.3135 7.33459C84.2731 8.24707 83.8849 9.1087 83.2296 9.74029C82.5742 10.3719 81.7022 10.7247 80.7949 10.7255C79.297 10.7255 78.3412 10.6675 76.9138 10.6675C75.8975 10.6675 75.1157 11.102 75.0716 12.0586C75.0215 13.1451 75.7467 13.698 76.7444 13.7775C77.0149 13.799 77.5948 13.8391 78.1037 13.8391C79.1692 13.8391 80.4067 14.5667 80.3814 16.0461C80.357 17.4886 79.0588 18.3024 77.6967 18.2385C76.9042 18.2014 76.2304 17.9997 75.4831 18.0187C74.6852 18.0391 74.1687 18.6997 74.0906 19.4961C74.0037 20.3825 74.4815 20.9625 75.3111 21.093C76.1201 21.1868 76.9352 21.2157 77.7488 21.1792C80.7448 21.1627 81.9506 23.0535 81.9041 25.1574C81.8575 26.093 81.4551 26.9746 80.7802 27.6193C80.1052 28.264 79.2096 28.6222 78.2792 28.6198C76.3941 28.6585 75.3677 28.6198 73.4641 28.6198Z"
          fill="currentColor"
        />
        <path
          id="Vector_6"
          d="M147.169 28.6569C144.709 28.6569 142.084 28.6521 139.614 28.6521C139.168 28.6521 137.988 28.6642 138.035 28.6492C138.374 28.5398 138.483 27.4531 138.564 26.9679C139.604 20.7215 139.876 16.1032 141.024 9.87749C141.794 5.70711 145.285 3.35381 149.161 3.50276C151.416 3.58943 151.261 3.53252 153.517 3.57746C157.756 3.66189 159.835 8.03081 157.83 12.1173C156.363 14.1606 154.554 13.816 151.669 13.816C151.071 13.816 150.805 13.3636 150.966 12.6343C151.112 11.9696 150.397 10.9357 149.456 11.0095C148.481 11.086 148.01 11.6716 147.879 12.5358C147.567 14.8073 147.194 17.0819 146.892 19.3548C146.77 20.2791 147.147 21.0373 148.082 21.2421C148.418 21.3279 148.772 21.2906 149.083 21.1374C149.393 20.984 149.64 20.7246 149.778 20.4053C150.705 18.5591 152.443 17.9516 154.203 18.4519C155.73 18.8858 156.836 20.5201 156.734 22.3592C156.572 24.0377 156.608 24.0486 156.282 25.7227C155.863 27.8735 153.892 28.6911 152.94 28.6728C150.481 28.6249 149.628 28.6568 147.169 28.6569Z"
          fill="currentColor"
        />
        <path
          id="Vector_7"
          d="M137.106 3.57525C139.078 3.64772 140.417 5.06684 140.417 6.967C140.417 8.79331 138.762 9.98185 137.826 10.0253C136.233 10.0994 134.972 11.3546 134.875 12.7648C134.245 17.5323 133.015 24.2222 132.393 28.9907C132.167 31.4583 130.761 31.6073 129.552 31.6409C127.808 31.6896 128.173 31.6531 126.414 31.6531C125.943 31.6531 124.581 31.647 124.604 31.635C124.911 31.4709 125.096 30.6032 125.183 30.0493C125.857 25.757 126.882 18.0196 127.521 13.7214C127.959 11.396 127.377 10.7355 125.822 10.2572C125.574 10.1808 125.045 10.0978 124.526 9.95284C123.645 9.55786 124.113 8.12666 124.26 7.05756C124.433 5.26386 125.426 3.68762 127.276 3.56079C128.975 3.44433 135.643 3.52152 137.106 3.57525Z"
          fill="currentColor"
        />
        <path
          id="Vector_8"
          d="M93.2241 31.4738C93.3766 31.0962 93.4682 30.6965 93.495 30.2899C93.5173 30.0857 93.497 29.8791 93.4353 29.6833C93.3737 29.4873 93.2722 29.3067 93.1371 29.1525C93.0022 28.9985 92.8368 28.8745 92.6516 28.7885C92.4662 28.7024 92.2652 28.6564 92.0611 28.6531C89.8854 28.6465 86.5554 28.6531 84.2974 28.6531C84.0274 28.6531 83.0568 28.6436 83.0625 28.6399C83.3543 28.4554 83.4613 27.4912 83.5105 27.1638C84.4522 20.9081 85.1626 16.2056 86.1029 9.94972C86.5467 6.52404 89.5941 3.33668 94.3281 3.5452C97.1389 3.48561 97.5827 3.48559 100.347 3.50039C102.52 3.51201 103.938 5.20372 103.989 7.20471C104.022 8.48964 102.909 10.87 100.455 10.9359C99.8335 10.9526 98.4751 10.9277 97.9257 10.8839C94.9248 10.6446 93.043 11.9655 92.6862 14.9152C92.4947 16.1992 92.2739 17.6818 92.0794 19.1246C91.6604 20.8694 92.4702 21.9731 93.6565 22.1461C94.1203 22.2238 94.5963 22.1276 94.9944 21.8756C95.3924 21.6236 95.6848 21.2334 95.8163 20.7788C95.9377 20.3807 95.9377 19.9552 95.8166 19.5569C95.6955 19.1587 95.4587 18.8061 95.1369 18.5447C94.5653 18.0758 93.8915 17.6066 94.0329 16.298C94.1965 14.7825 95.9748 14.2337 97.2159 14.2586C100.75 14.3294 102.909 15.7039 102.255 20.0215C101.856 23.4002 101.466 25.1383 101.028 28.5123C100.729 30.4199 98.9025 31.5026 97.2193 31.5026C95.3738 31.5026 95.5695 31.4828 94.0116 31.4828C93.7944 31.4828 93.2241 31.4738 93.2241 31.4738Z"
          fill="currentColor"
        />
        <path
          id="Vector_9"
          d="M169.162 31.6413C168.737 31.6409 167.236 31.6104 167.281 31.5865C167.823 31.2951 168.184 29.153 168.29 28.4278C168.638 26.0232 169.121 22.9958 169.479 20.5927C169.644 19.5195 169.388 18.6711 168.331 18.4133C167.249 18.1491 166.528 18.9913 166.446 20.1304C165.963 22.6483 165.921 23.5875 165.519 26.0661C165.322 27.2795 164.019 28.7161 162.789 28.7408C162.654 28.7434 160.819 28.6992 159.271 28.7037C158.04 28.7072 157.46 28.689 157.379 28.6798C157.696 28.3791 157.904 27.7139 157.985 27.1853C158.189 25.8616 158.385 24.2829 158.607 22.8589C159.421 16.6949 160.591 9.99936 161.442 3.84018C161.796 1.27245 163.385 -0.198489 165.474 0.0216616C167.821 0.269051 169.197 2.20106 168.724 4.8416C168.356 6.90096 167.983 9.53698 167.61 11.5944C167.406 12.7193 167.744 13.5266 168.841 13.7078C169.939 13.8894 170.439 13.0985 170.594 12.2218C170.937 10.2986 171.11 8.43722 171.382 6.47615C171.661 4.46183 174.046 2.76917 176.416 3.8179C177.909 4.47833 178.788 6.03679 178.549 7.83C177.805 13.3835 175.832 27.2419 175.608 28.9107C175.374 30.6443 173.799 31.5728 172.077 31.6594C171.613 31.6828 169.713 31.6418 169.162 31.6413Z"
          fill="currentColor"
        />
        <path
          id="Vector_10"
          d="M20.7981 4.31398C19.1051 2.11609 16.9178 1.22656 14.8785 1.22656C9.76815 1.22656 4.56406 5.77827 3.84943 10.8729L1.98905 24.1366C1.98905 24.1366 1.3485 28.1221 0.714844 28.7401C0.712681 28.7423 9.37317 28.7033 9.37317 28.7033L9.93674 24.6644L9.93802 24.6568L10.2471 22.4414C10.5658 20.9104 11.4718 20.5665 12.3317 20.617C13.1219 20.6635 13.6297 21.2035 13.5196 21.9926L13.0642 25.2565C13.0634 25.2617 13.063 25.2668 13.0623 25.2721C13.0623 25.2721 12.806 26.965 12.6351 27.6737C12.5628 28.0397 12.4144 28.3862 12.1995 28.6903L20.6079 28.7033L22.8777 12.5114C23.0928 11.0744 23.0196 9.60862 22.6625 8.20062C22.3052 6.79264 21.6712 5.47107 20.7981 4.31398ZM15.1199 10.5628L14.5551 14.6068C14.4942 15.0455 14.2627 15.4419 13.9115 15.7087C13.5603 15.9756 13.1182 16.0911 12.6825 16.0298C12.2467 15.9685 11.853 15.7354 11.588 15.3818C11.3229 15.0282 11.2082 14.5831 11.2691 14.1444L11.5332 12.2303C11.6435 11.4301 11.9363 10.3685 12.5456 9.84269C12.9546 9.47775 13.4873 9.28506 14.0335 9.30442C14.1904 9.30442 14.3456 9.3383 14.4884 9.40384C14.6313 9.46938 14.7585 9.56506 14.8615 9.68434C14.9645 9.80361 15.0408 9.94374 15.0854 10.0953C15.13 10.2468 15.1418 10.4063 15.1199 10.5628Z"
          fill="currentColor"
        />
        <path
          id="Vector_11"
          d="M113.345 3.38672H111.968C110.519 3.38677 109.118 3.91412 108.024 4.87162C106.931 5.82914 106.217 7.15233 106.016 8.59742L104.764 17.5889C104.571 18.9687 104.676 20.3743 105.069 21.7102C105.462 23.0463 106.136 24.2818 107.044 25.3332C107.952 26.3848 109.074 27.2278 110.333 27.8055C111.593 28.3833 112.961 28.6823 114.345 28.6821H115.737C117.179 28.682 118.572 28.1578 119.661 27.206C120.75 26.254 121.461 24.9385 121.663 23.501L122.93 14.5004C123.125 13.1187 123.022 11.7111 122.63 10.3727C122.237 9.0344 121.563 7.79658 120.655 6.74291C119.746 5.68925 118.623 4.84429 117.362 4.26529C116.101 3.68628 114.731 3.38667 113.345 3.38672ZM116.078 14.2677L115.741 18.8178C115.672 19.7455 114.857 20.2939 113.829 20.1036C112.578 19.872 111.52 19.0154 111.616 17.5269L111.771 15.3726C111.836 14.4718 112.193 13.2917 112.83 12.8737C113.09 12.7005 113.383 12.5833 113.69 12.5296C113.997 12.4759 114.312 12.4867 114.614 12.5615C115.444 12.7122 116.133 13.5198 116.078 14.2677Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
