type Properties = {
  className?: string;
  size: number;
};

export const Tiktok = ({ size, className }: Properties) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4429_1622" fill="white">
        <path d="M17.17 1H13.4623V15.9855C13.4623 17.771 12.0364 19.2377 10.2618 19.2377C8.48716 19.2377 7.06115 17.771 7.06115 15.9855C7.06115 14.2319 8.45547 12.7971 10.1667 12.7333V8.97103C6.39567 9.03477 3.35352 12.1275 3.35352 15.9855C3.35352 19.8754 6.45905 23 10.2935 23C14.1278 23 17.2333 19.8435 17.2333 15.9855V8.30143C18.6277 9.32174 20.3389 9.92754 22.1452 9.95944V6.1971C19.3566 6.10145 17.17 3.80579 17.17 1Z" />
      </mask>
      <path
        d="M17.17 1H13.4623V15.9855C13.4623 17.771 12.0364 19.2377 10.2618 19.2377C8.48716 19.2377 7.06115 17.771 7.06115 15.9855C7.06115 14.2319 8.45547 12.7971 10.1667 12.7333V8.97103C6.39567 9.03477 3.35352 12.1275 3.35352 15.9855C3.35352 19.8754 6.45905 23 10.2935 23C14.1278 23 17.2333 19.8435 17.2333 15.9855V8.30143C18.6277 9.32174 20.3389 9.92754 22.1452 9.95944V6.1971C19.3566 6.10145 17.17 3.80579 17.17 1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.916667"
        mask="url(#path-1-inside-1_4429_1622)"
      />
    </svg>
  );
};
