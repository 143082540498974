type Properties = {
  className?: string;
  foxIconClassName?: string;
};

export const MetamaskLogo = ({ className, foxIconClassName }: Properties) => {
  return (
    <svg
      className={className}
      width="80"
      height="15"
      viewBox="0 0 80 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logos">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M70.1393 7.24997C69.7309 6.97871 69.273 6.78144 68.8399 6.54717C68.5553 6.38688 68.2583 6.25126 68.0232 6.05398C67.6148 5.72107 67.7014 5.05526 68.1222 4.75935C68.7409 4.35246 69.768 4.5744 69.8794 5.41283C69.8794 5.42516 69.8918 5.43749 69.9165 5.43749H70.857C70.8818 5.43749 70.8941 5.42516 70.8941 5.4005C70.8446 4.821 70.6219 4.34013 70.2135 4.03189C69.8175 3.73597 69.372 3.57568 68.8894 3.57568C66.4144 3.57568 66.1917 6.17728 67.5282 7.00337C67.6767 7.10201 68.9884 7.75549 69.4463 8.03908C69.9041 8.32266 70.0526 8.84052 69.8546 9.25973C69.669 9.62963 69.1988 9.88855 68.7285 9.86389C68.2088 9.83923 67.8128 9.55565 67.6767 9.1241C67.6519 9.05012 67.6395 8.88984 67.6395 8.82819C67.6395 8.81586 67.6272 8.7912 67.6024 8.7912H66.5877C66.5753 8.7912 66.5505 8.80353 66.5505 8.82819C66.5505 9.55565 66.7362 9.96253 67.2312 10.3324C67.7014 10.6777 68.2088 10.8256 68.7409 10.8256C70.1269 10.8256 70.8446 10.0488 70.9808 9.23507C71.0798 8.43363 70.8446 7.7185 70.1393 7.24997Z"
              fill="currentColor"
            />
            <path
              id="Vector_2"
              d="M26.728 3.71143H25.1935C25.1812 3.71143 25.1688 3.72376 25.1564 3.73609L24.3273 6.47331C24.3149 6.51029 24.2654 6.51029 24.253 6.47331L23.4239 3.73609C23.4239 3.72376 23.4115 3.71143 23.3868 3.71143H21.8399C21.8275 3.71143 21.8028 3.72376 21.8028 3.74841V10.7271C21.8028 10.7394 21.8152 10.7641 21.8399 10.7641H22.8547C22.867 10.7641 22.8918 10.7518 22.8918 10.7271V5.42527C22.8918 5.38828 22.9537 5.37595 22.966 5.41294L23.8075 8.16249L23.8694 8.35977C23.8694 8.3721 23.8818 8.38443 23.9065 8.38443H24.6862C24.6985 8.38443 24.7109 8.3721 24.7233 8.35977L24.7852 8.16249L25.6266 5.41294C25.639 5.37595 25.7009 5.38828 25.7009 5.42527V10.7271C25.7009 10.7394 25.7133 10.7641 25.738 10.7641H26.7528C26.7651 10.7641 26.7899 10.7518 26.7899 10.7271V3.74841C26.7651 3.72376 26.7528 3.71143 26.728 3.71143Z"
              fill="currentColor"
            />
            <path
              id="Vector_3"
              d="M54.6211 3.71143C54.6087 3.71143 54.5964 3.72376 54.584 3.73609L53.7549 6.47331C53.7425 6.51029 53.693 6.51029 53.6806 6.47331L52.8515 3.73609C52.8515 3.72376 52.8391 3.71143 52.8144 3.71143H51.2675C51.2551 3.71143 51.2304 3.72376 51.2304 3.74841V10.7271C51.2304 10.7394 51.2428 10.7641 51.2675 10.7641H52.2822C52.2946 10.7641 52.3194 10.7518 52.3194 10.7271V5.42527C52.3194 5.38828 52.3812 5.37595 52.3936 5.41294L53.2351 8.16249L53.297 8.35977C53.297 8.3721 53.3094 8.38443 53.3341 8.38443H54.1137C54.1261 8.38443 54.1385 8.3721 54.1509 8.35977L54.2127 8.16249L55.0542 5.41294C55.0666 5.37595 55.1285 5.38828 55.1285 5.42527V10.7271C55.1285 10.7394 55.1409 10.7641 55.1656 10.7641H56.1804C56.1927 10.7641 56.2175 10.7518 56.2175 10.7271V3.74841C56.2175 3.73608 56.2051 3.71143 56.1804 3.71143H54.6211Z"
              fill="currentColor"
            />
            <path
              id="Vector_4"
              d="M41.5532 3.71143H36.7641C36.7517 3.71143 36.727 3.72376 36.727 3.74841V4.62383C36.727 4.63616 36.7393 4.66082 36.7641 4.66082H38.6203V10.7271C38.6203 10.7394 38.6327 10.7641 38.6574 10.7641H39.6722C39.6846 10.7641 39.7093 10.7518 39.7093 10.7271V4.66082H41.5656C41.5779 4.66082 41.6027 4.64849 41.6027 4.62383V3.74841C41.5903 3.72376 41.5779 3.71143 41.5532 3.71143Z"
              fill="currentColor"
            />
            <path
              id="Vector_5"
              d="M45.1048 7.66918L45.8473 4.94429C45.8597 4.9073 45.9092 4.9073 45.9216 4.94429L46.6641 7.66918C46.6641 7.69384 46.6517 7.7185 46.6269 7.7185H45.1419C45.1172 7.7185 45.0924 7.69384 45.1048 7.66918ZM47.5303 10.7516H48.4584C48.4832 10.7516 48.4956 10.727 48.4956 10.7023L46.5898 3.69898C46.5898 3.68665 46.5774 3.67432 46.5527 3.67432H45.2286C45.2162 3.67432 45.2038 3.68665 45.1914 3.69898L43.2857 10.7023C43.2857 10.727 43.2981 10.7516 43.3228 10.7516H44.2509C44.2633 10.7516 44.2757 10.7393 44.2881 10.727L44.8449 8.69255C44.8449 8.68022 44.8573 8.66789 44.8821 8.66789H46.9239C46.9363 8.66789 46.9487 8.68022 46.9611 8.69255L47.5179 10.727C47.4932 10.7393 47.5179 10.7516 47.5303 10.7516Z"
              fill="currentColor"
            />
            <path
              id="Vector_6"
              d="M60.8333 7.66918L61.5758 4.94429C61.5882 4.9073 61.6377 4.9073 61.6501 4.94429L62.3926 7.66918C62.3926 7.69384 62.3802 7.7185 62.3554 7.7185H60.8704C60.8457 7.7185 60.8209 7.69384 60.8333 7.66918ZM63.2588 10.7516H64.1869C64.2117 10.7516 64.2241 10.727 64.2241 10.7023L62.3183 3.69898C62.3183 3.68665 62.3059 3.67432 62.2812 3.67432H60.9571C60.9447 3.67432 60.9323 3.68665 60.9199 3.69898L59.0018 10.7023C59.0018 10.727 59.0142 10.7516 59.039 10.7516H59.9671C59.9794 10.7516 59.9918 10.7393 60.0042 10.727L60.5611 8.69255C60.5611 8.68022 60.5735 8.66789 60.5982 8.66789H62.6401C62.6524 8.66789 62.6648 8.68022 62.6772 8.69255L63.2341 10.727C63.2217 10.7393 63.2341 10.7516 63.2588 10.7516Z"
              fill="currentColor"
            />
            <path
              id="Vector_7"
              d="M30.886 9.7285V7.55845C30.886 7.54612 30.8984 7.52146 30.9231 7.52146H33.6209C33.6333 7.52146 33.658 7.50913 33.658 7.48447V6.60906C33.658 6.59673 33.6456 6.57207 33.6209 6.57207H30.9231C30.9108 6.57207 30.886 6.55974 30.886 6.53508V4.6856C30.886 4.67327 30.8984 4.64862 30.9231 4.64862H33.9921C34.0045 4.64862 34.0293 4.63629 34.0293 4.61163V3.73621C34.0293 3.72388 34.0169 3.69922 33.9921 3.69922H29.8342C29.8218 3.69922 29.797 3.71155 29.797 3.73621V10.7026C29.797 10.7149 29.8094 10.7395 29.8342 10.7395H34.1159C34.1283 10.7395 34.153 10.7272 34.153 10.7026V9.77782C34.153 9.76549 34.1406 9.74083 34.1159 9.74083H30.9108C30.8984 9.76549 30.886 9.75316 30.886 9.7285Z"
              fill="currentColor"
            />
            <path
              id="Vector_8"
              d="M79.3957 10.7024L75.8936 7.0898C75.8813 7.07747 75.8813 7.05281 75.8936 7.04048L79.0492 3.77307C79.074 3.74842 79.0492 3.71143 79.0245 3.71143H77.7375C77.7251 3.71143 77.7251 3.71143 77.7127 3.72376L75.0398 6.49797C75.015 6.52263 74.9779 6.49797 74.9779 6.47331V3.74841C74.9779 3.73608 74.9655 3.71143 74.9408 3.71143H73.926C73.9136 3.71143 73.8889 3.72376 73.8889 3.74841V10.7271C73.8889 10.7394 73.9013 10.7641 73.926 10.7641H74.9408C74.9531 10.7641 74.9779 10.7518 74.9779 10.7271V7.65697C74.9779 7.63231 75.015 7.60765 75.0398 7.63231L78.0716 10.7518C78.0716 10.7518 78.084 10.7641 78.0964 10.7641H79.3834C79.3957 10.7641 79.4205 10.7148 79.3957 10.7024Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_3" className={foxIconClassName}>
            <path
              id="Vector_9"
              d="M15.0232 0L8.84808 4.50039L9.99895 1.83714L15.0232 0Z"
              fill="#88918D"
            />
            <path
              id="Vector_10"
              d="M0.779633 0L6.90522 4.53737L5.80386 1.83714L0.779633 0Z"
              fill="#88918D"
            />
            <path
              id="Vector_11"
              d="M12.7957 10.4309L11.1622 12.8969L14.6767 13.8586L15.679 10.4802L12.7957 10.4309Z"
              fill="#88918D"
            />
            <path
              id="Vector_12"
              d="M0.123749 10.4802L1.12612 13.8586L4.6406 12.8969L3.00711 10.4309L0.123749 10.4802Z"
              fill="#88918D"
            />
            <path
              id="Vector_13"
              d="M4.45497 6.25098L3.47736 7.70589L6.95471 7.86618L6.84334 4.16724L4.45497 6.25098Z"
              fill="#88918D"
            />
            <path
              id="Vector_14"
              d="M11.3478 6.25135L8.92233 4.13062L8.84808 7.86655L12.3254 7.70626L11.3478 6.25135Z"
              fill="#88918D"
            />
            <path
              id="Vector_15"
              d="M4.64059 12.8974L6.74433 11.8986L4.93759 10.5054L4.64059 12.8974Z"
              fill="#88918D"
            />
            <path
              id="Vector_16"
              d="M9.05844 11.8986L11.1622 12.8974L10.8652 10.5054L9.05844 11.8986Z"
              fill="#88918D"
            />
            <path
              id="Vector_17"
              d="M11.1622 12.8972L9.05844 11.8984L9.23169 13.2424L9.20694 13.8096L11.1622 12.8972Z"
              fill="#C0C9C5"
            />
            <path
              id="Vector_18"
              d="M4.64059 12.8972L6.59583 13.8096L6.58346 13.2424L6.74433 11.8984L4.64059 12.8972Z"
              fill="#C0C9C5"
            />
            <path
              id="Vector_19"
              d="M6.63299 9.61725L4.88812 9.11173L6.11324 8.55688L6.63299 9.61725Z"
              fill="#2D3632"
            />
            <path
              id="Vector_20"
              d="M9.1698 9.61725L9.68955 8.55688L10.927 9.11173L9.1698 9.61725Z"
              fill="#2D3632"
            />
            <path
              id="Vector_21"
              d="M4.64057 12.8969L4.94994 10.4309L3.00708 10.4802L4.64057 12.8969Z"
              fill="#767F7B"
            />
            <path
              id="Vector_22"
              d="M10.8528 10.4309L11.1622 12.8969L12.7957 10.4802L10.8528 10.4309Z"
              fill="#767F7B"
            />
            <path
              id="Vector_23"
              d="M12.3254 7.70605L8.84808 7.86634L9.16983 9.61718L9.68958 8.55681L10.9271 9.11166L12.3254 7.70605Z"
              fill="#767F7B"
            />
            <path
              id="Vector_24"
              d="M4.8881 9.11166L6.11322 8.55681L6.63296 9.61718L6.95471 7.86634L3.47736 7.70605L4.8881 9.11166Z"
              fill="#767F7B"
            />
            <path
              id="Vector_25"
              d="M3.47736 7.70605L4.9376 10.5049L4.8881 9.11166L3.47736 7.70605Z"
              fill="#88918D"
            />
            <path
              id="Vector_26"
              d="M10.927 9.11166L10.8652 10.5049L12.3254 7.70605L10.927 9.11166Z"
              fill="#88918D"
            />
            <path
              id="Vector_27"
              d="M6.95471 7.86646L6.63297 9.61729L7.04134 11.701L7.12796 8.96381L6.95471 7.86646Z"
              fill="#88918D"
            />
            <path
              id="Vector_28"
              d="M8.84808 7.86646L8.67484 8.95148L8.76146 11.701L9.16983 9.61729L8.84808 7.86646Z"
              fill="#88918D"
            />
            <path
              id="Vector_29"
              d="M9.16982 9.61734L8.76144 11.7011L9.05844 11.8984L10.8652 10.5051L10.9271 9.11182L9.16982 9.61734Z"
              fill="#959E9A"
            />
            <path
              id="Vector_30"
              d="M4.88812 9.11182L4.93762 10.5051L6.74436 11.8984L7.04136 11.7011L6.63299 9.61734L4.88812 9.11182Z"
              fill="#959E9A"
            />
            <path
              id="Vector_31"
              d="M9.20695 13.8094L9.2317 13.2422L9.07082 13.1189H6.73196L6.58346 13.2422L6.59583 13.8094L4.64059 12.897L5.32122 13.4518L6.70721 14.4012H9.0832L10.4816 13.4518L11.1622 12.897L9.20695 13.8094Z"
              fill="#ABB4B0"
            />
            <path
              id="Vector_32"
              d="M9.05845 11.8982L8.76145 11.7009H7.04134L6.74434 11.8982L6.58347 13.2422L6.73196 13.1189H9.07083L9.2317 13.2422L9.05845 11.8982Z"
              fill="#2F343B"
            />
            <path
              id="Vector_33"
              d="M15.283 4.78397L15.8028 2.30568L15.0232 0L9.05844 4.3401L11.3478 6.25122L14.59 7.18829L15.3078 6.36219L14.9984 6.14025L15.4934 5.69638L15.1098 5.41279L15.6048 5.03057L15.283 4.78397Z"
              fill="#454E4A"
            />
            <path
              id="Vector_34"
              d="M0 2.30568L0.519748 4.78397L0.185624 5.03057L0.692997 5.41279L0.309373 5.69638L0.804371 6.14025L0.494997 6.36219L1.21274 7.18829L4.45498 6.25122L6.74434 4.3401L0.779621 0L0 2.30568Z"
              fill="#454E4A"
            />
            <path
              id="Vector_35"
              d="M14.59 7.18829L11.3478 6.25122L12.3254 7.70614L10.8652 10.505L12.7957 10.4803H15.679L14.59 7.18829Z"
              fill="#959E9A"
            />
            <path
              id="Vector_36"
              d="M4.45498 6.25122L1.21274 7.18829L0.123749 10.4803H3.00711L4.9376 10.505L3.47736 7.70614L4.45498 6.25122Z"
              fill="#959E9A"
            />
            <path
              id="Vector_37"
              d="M8.84807 7.8662L9.05844 4.33987L9.99894 1.83691H5.80383L6.74433 4.33987L6.9547 7.8662L7.02895 8.96355L7.04133 11.7008H8.76144L8.77382 8.96355L8.84807 7.8662Z"
              fill="#959E9A"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
