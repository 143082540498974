type Properties = {
  className?: string;
};

export const AcrossLogo = ({ className }: Properties) => {
  return (
    <svg
      className={className}
      width="184"
      height="40"
      viewBox="0 0 184 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Images/partners/across">
        <path
          id="Vector"
          d="M73.4457 30.008C73.24 30.008 73.1224 29.9198 73.0636 29.7141L72.1525 26.7751C72.1231 26.6869 72.0644 26.6575 72.0056 26.6575H64.6286C64.5699 26.6575 64.5111 26.6869 64.4817 26.7751L63.6 29.7141C63.5412 29.9198 63.4236 30.008 63.2179 30.008H59.3972C59.2796 30.008 59.1915 29.9786 59.1327 29.9198C59.0739 29.8317 59.0739 29.7435 59.1033 29.5965L65.5691 9.31734C65.6279 9.11161 65.7455 9.02344 65.9512 9.02344H70.6536C70.8594 9.02344 70.9769 9.11161 71.0357 9.31734L77.5015 29.5965C77.5309 29.6259 77.5309 29.6847 77.5309 29.7435C77.5309 29.9198 77.4134 30.008 77.2076 30.008H73.4457ZM65.5397 23.1895C65.5103 23.307 65.5691 23.3658 65.6573 23.3658H70.9475C71.0651 23.3658 71.0945 23.307 71.0651 23.1895L68.3612 14.2843C68.3318 14.1961 68.3024 14.1667 68.273 14.1961C68.2436 14.1961 68.2142 14.2255 68.1849 14.2843L65.5397 23.1895Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M89.9643 30.2401C88.4066 30.2401 87.0252 29.9462 85.8496 29.3291C84.674 28.7119 83.7629 27.8595 83.1164 26.7427C82.4698 25.6553 82.1465 24.3621 82.1465 22.922V16.0153C82.1465 14.5752 82.4698 13.3114 83.1164 12.1946C83.7629 11.1072 84.674 10.2548 85.8496 9.63765C87.0252 9.04985 88.4066 8.72656 89.9643 8.72656C91.4925 8.72656 92.8445 9.02046 94.0201 9.60827C95.1957 10.1667 96.1068 10.9896 96.7534 12.0476C97.4 13.0763 97.7526 14.3107 97.7526 15.6626C97.7526 15.839 97.6351 15.9565 97.4 15.9859L93.9025 16.1917H93.8438C93.638 16.1917 93.5499 16.0741 93.5499 15.8684C93.5499 14.8103 93.2266 13.958 92.5506 13.3114C91.904 12.6648 91.0517 12.3415 89.9936 12.3415C88.9062 12.3415 88.0539 12.6648 87.3779 13.3114C86.7313 13.958 86.4081 14.8103 86.4081 15.8684V23.0983C86.4081 24.127 86.7313 24.9793 87.3779 25.6259C88.0245 26.2725 88.9062 26.5958 89.9936 26.5958C91.0517 26.5958 91.904 26.2725 92.5506 25.6259C93.1972 24.9793 93.5499 24.1564 93.5499 23.0983C93.5499 22.8926 93.6674 22.7751 93.9025 22.7751L97.4 22.922C97.4881 22.922 97.5763 22.9514 97.6351 23.0102C97.7233 23.0689 97.7526 23.1277 97.7526 23.2159C97.7526 24.5972 97.4294 25.8022 96.7534 26.8897C96.1068 27.9477 95.1957 28.7706 94.0201 29.3584C92.8445 29.9462 91.4925 30.2401 89.9643 30.2401Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M115.357 30.008C115.151 30.008 115.004 29.9198 114.945 29.7435L111.183 21.4261C111.154 21.3379 111.095 21.3085 111.007 21.3085H108.215C108.127 21.3085 108.068 21.3673 108.068 21.4555V29.6553C108.068 29.7435 108.039 29.8317 107.95 29.9198C107.892 29.9786 107.804 30.008 107.715 30.008H104.218C104.13 30.008 104.042 29.9786 103.953 29.9198C103.895 29.8317 103.865 29.7435 103.865 29.6553V9.37611C103.865 9.28794 103.895 9.19978 103.953 9.141C104.042 9.05283 104.13 9.02344 104.218 9.02344H112.8C114.093 9.02344 115.21 9.28794 116.15 9.81697C117.12 10.346 117.884 11.0807 118.413 12.0506C118.942 13.0205 119.236 14.1079 119.236 15.3717C119.236 16.7236 118.884 17.8993 118.208 18.8691C117.532 19.839 116.591 20.515 115.357 20.8971C115.269 20.9264 115.239 21.0146 115.269 21.1028L119.354 29.5965C119.383 29.6847 119.413 29.7435 119.413 29.7729C119.413 29.9198 119.295 30.008 119.089 30.008H115.357ZM108.215 12.6384C108.127 12.6384 108.068 12.6972 108.068 12.7854V17.9286C108.068 18.0168 108.127 18.0756 108.215 18.0756H112.153C113.006 18.0756 113.682 17.8111 114.181 17.3115C114.71 16.8118 115.004 16.1652 115.004 15.3717C115.004 14.5782 114.74 13.9316 114.181 13.432C113.652 12.9029 112.976 12.6384 112.153 12.6384H108.215Z"
          fill="currentColor"
        />
        <path
          id="Vector_4"
          d="M132.726 30.3283C131.169 30.3283 129.787 30.005 128.582 29.3584C127.407 28.7119 126.496 27.8302 125.82 26.6839C125.173 25.5377 124.82 24.1858 124.82 22.6869V16.2798C124.82 14.8103 125.144 13.4878 125.82 12.3415C126.466 11.1953 127.407 10.3136 128.582 9.69643C129.787 9.04985 131.169 8.72656 132.726 8.72656C134.313 8.72656 135.695 9.04985 136.87 9.69643C138.075 10.3136 138.986 11.1953 139.662 12.3415C140.338 13.4878 140.662 14.7809 140.662 16.2798V22.6869C140.662 24.1858 140.338 25.5083 139.662 26.6839C138.986 27.8595 138.075 28.7413 136.87 29.3878C135.665 30.0344 134.313 30.3283 132.726 30.3283ZM132.726 26.7133C133.814 26.7133 134.725 26.3606 135.401 25.6553C136.077 24.9499 136.429 24.0388 136.429 22.8632V16.2504C136.429 15.1042 136.077 14.1637 135.401 13.4584C134.754 12.753 133.843 12.3709 132.726 12.3709C131.639 12.3709 130.728 12.7236 130.052 13.4584C129.405 14.1637 129.052 15.0748 129.052 16.2504V22.8632C129.052 24.0094 129.376 24.9499 130.052 25.6553C130.728 26.3606 131.609 26.7133 132.726 26.7133Z"
          fill="currentColor"
        />
        <path
          id="Vector_5"
          d="M154.092 30.2439C152.505 30.2439 151.094 29.9794 149.889 29.4797C148.684 28.9507 147.773 28.216 147.097 27.2755C146.45 26.3056 146.127 25.2182 146.127 23.9544V23.3078C146.127 23.2196 146.156 23.1315 146.215 23.0727C146.303 22.9845 146.391 22.9551 146.48 22.9551H149.889C149.977 22.9551 150.065 22.9845 150.124 23.0727C150.212 23.1315 150.242 23.2196 150.242 23.3078V23.7486C150.242 24.5422 150.624 25.2182 151.358 25.7766C152.093 26.3056 153.092 26.5995 154.356 26.5995C155.414 26.5995 156.208 26.3938 156.737 25.9529C157.266 25.4827 157.53 24.9243 157.53 24.2777C157.53 23.8074 157.383 23.396 157.06 23.0727C156.737 22.72 156.296 22.4555 155.738 22.191C155.208 21.9265 154.327 21.5738 153.122 21.1329C151.77 20.6627 150.653 20.1924 149.713 19.7222C148.801 19.252 148.008 18.6054 147.361 17.7825C146.744 16.9301 146.421 15.9015 146.421 14.6965C146.421 13.4915 146.744 12.4334 147.361 11.5517C147.979 10.6406 148.831 9.96467 149.948 9.49442C151.064 9.02418 152.328 8.78906 153.768 8.78906C155.297 8.78906 156.649 9.05358 157.824 9.61199C159.029 10.141 159.94 10.9052 160.616 11.9044C161.292 12.8743 161.645 13.9911 161.645 15.2549V15.6957C161.645 15.7839 161.616 15.8721 161.527 15.9603C161.469 16.019 161.38 16.0484 161.292 16.0484H157.854C157.765 16.0484 157.677 16.019 157.589 15.9603C157.53 15.8721 157.501 15.7839 157.501 15.6957V15.4312C157.501 14.5789 157.148 13.8736 156.443 13.3151C155.767 12.7273 154.826 12.4041 153.621 12.4041C152.681 12.4041 151.946 12.6098 151.388 12.9919C150.859 13.4033 150.594 13.9323 150.594 14.6377C150.594 15.1373 150.741 15.5488 151.035 15.9015C151.358 16.2542 151.829 16.5481 152.416 16.842C153.034 17.1065 153.974 17.4592 155.267 17.9294C156.678 18.4584 157.795 18.8993 158.588 19.3107C159.411 19.7222 160.146 20.31 160.793 21.1035C161.439 21.8971 161.792 22.8963 161.792 24.1601C161.792 26.0411 161.116 27.54 159.735 28.6274C158.294 29.7149 156.443 30.2439 154.092 30.2439Z"
          fill="currentColor"
        />
        <path
          id="Vector_6"
          d="M174.93 30.2439C173.343 30.2439 171.932 29.9794 170.727 29.4797C169.522 28.9507 168.611 28.216 167.935 27.2755C167.288 26.3056 166.965 25.2182 166.965 23.9544V23.3078C166.965 23.2196 166.994 23.1315 167.053 23.0727C167.141 22.9845 167.229 22.9551 167.318 22.9551H170.727C170.815 22.9551 170.903 22.9845 170.962 23.0727C171.05 23.1315 171.079 23.2196 171.079 23.3078V23.7486C171.079 24.5422 171.462 25.2182 172.196 25.7766C172.931 26.3056 173.93 26.5995 175.194 26.5995C176.252 26.5995 177.046 26.3938 177.575 25.9529C178.104 25.4827 178.368 24.9243 178.368 24.2777C178.368 23.8074 178.221 23.396 177.898 23.0727C177.575 22.72 177.134 22.4555 176.575 22.191C176.046 21.9265 175.165 21.5738 173.96 21.1329C172.608 20.6627 171.491 20.1924 170.55 19.7222C169.639 19.252 168.846 18.6054 168.199 17.7825C167.582 16.9301 167.259 15.9015 167.259 14.6965C167.259 13.4915 167.582 12.4334 168.199 11.5517C168.816 10.6406 169.669 9.96467 170.786 9.49442C171.873 9.02418 173.166 8.78906 174.606 8.78906C176.135 8.78906 177.487 9.05358 178.662 9.61199C179.867 10.141 180.778 10.9052 181.454 11.9044C182.13 12.8743 182.483 13.9911 182.483 15.2549V15.6957C182.483 15.7839 182.453 15.8721 182.365 15.9603C182.307 16.019 182.218 16.0484 182.13 16.0484H178.633C178.545 16.0484 178.456 16.019 178.368 15.9603C178.309 15.8721 178.28 15.7839 178.28 15.6957V15.4312C178.28 14.5789 177.927 13.8736 177.222 13.3151C176.546 12.7273 175.606 12.4041 174.401 12.4041C173.46 12.4041 172.725 12.6098 172.196 12.9919C171.667 13.4033 171.403 13.9323 171.403 14.6377C171.403 15.1373 171.55 15.5488 171.844 15.9015C172.167 16.2542 172.637 16.5481 173.225 16.842C173.842 17.1065 174.783 17.4592 176.076 17.9294C177.487 18.4584 178.603 18.8993 179.397 19.3107C180.22 19.7222 180.955 20.31 181.601 21.1035C182.248 21.8971 182.6 22.8963 182.6 24.1601C182.6 26.0411 181.924 27.54 180.543 28.6274C179.162 29.7149 177.281 30.2439 174.93 30.2439Z"
          fill="currentColor"
        />
        <path
          id="Vector_7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.9735 0L40 4.02645L26.5393 17.4871C25.8339 15.6356 24.3644 14.166 22.5129 13.4607L35.9735 0ZM17.4871 13.4607L4.02645 0L0 4.02645L13.4607 17.4871C14.1954 15.6356 15.665 14.166 17.4871 13.4607ZM13.4607 22.5129L0 35.9735L4.02645 40L17.4871 26.5393C15.665 25.8339 14.1954 24.3644 13.4607 22.5129ZM22.5129 26.5393L35.9735 40L40 35.9735L26.5393 22.5129C25.8339 24.3644 24.3644 25.8339 22.5129 26.5393Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
